import * as React from "react";
import Layout from "@layout";
import ErrorArea from "../container/error-404";

const NotFoundPage = () => (
    <Layout>
        <ErrorArea />
    </Layout>
);

export default NotFoundPage;
